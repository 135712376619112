<form #modelForm="ngForm"
      fxLayout="column"
      fxFlex
      class="ft-padding"
>
  <div *ngIf="showConsumables" fxLayout="column">
    <div fxFlex="nogrow" fxLayoutAlign="center center" class="block-header">
      <h1>{{ "settings.consumable_list"|translate }}</h1>
      <span fxFlex=""></span>
      <ng-template [ftAcl]="{ resource: 'prescription-consumable', action: 'create' }">
        <button type="button"
          color="primary"
          mat-mini-fab
          (click)="AddConsumable()"
          [matTooltip]="'settings.consumable_list' | translate"
        >
          <mat-icon fontIcon="mdi-store-search-outline" fontSet="mdi"></mat-icon>
        </button>
      </ng-template>
    </div>
    <ft-generic-body-table
      [remote]="false"
      [source]="linesSource$"
      [selectable]="false"
      [hasSearch]="false"
      [hasPagination]="true"
      [columns]="linesColumns | async"
      [multiActions]="[]"
      [pageSize]="5"
    >
    </ft-generic-body-table>
  </div>

  <ng-container *ngIf="model?.blocks">
    <div *ngFor="let b of model.blocks; trackBy: trackByFn; let blockIdx = index">
      <pr-prescription-block *ngIf="showBlock(b)" [block]="b" (blockChange)="handleChange(b, $event)"
                             (blockValid)="handleBlockValid(b.name, $event)"
                             (fieldFileChange)="handleFieldFile($event,blockIdx)"
                             (fieldFileDelete)="handleFieldFileDelete($event,blockIdx)"
                             (fieldFilePreview)="previewFile($event)"
                             [template]="model"></pr-prescription-block>
    </div>
  </ng-container>
</form>
